import styled from '@emotion/styled'
import { space } from 'styled-system'
import { Bold } from './Bold'
import { breakPoints } from '../Layout/theme'

const Paragraph = styled.p`
  font-family: BloggerSans-Light;
  font-size: ${({ small }) => (small ? '16px' : '19px')};
  color: #282c2f;
  letter-spacing: 0;
  line-height: ${({ small }) => (small ? '22px' : '30px')};;
  margin-bottom: 0.8em;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  ${space}

  & ${Bold}, strong {
    font-family: BloggerSans-Medium;
  }

  @media (max-width: ${breakPoints.xs}) {
    font-size: ${({ small }) => (small ? '14px' : '16px')};
  }
`

export { Paragraph }
