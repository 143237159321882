import React, { useState } from 'react'
import { TopBarText } from './TopBarText'
import { Container } from '../Grid'
import styled from '@emotion/styled'
import {
  MobileNavigationToggle,
  MobileNavigationMenu,
} from '../Navigation/MobileNavigation'
import { breakPoints } from '../Layout/theme'

const Background = styled.div`
  background-color: ${({ theme }) => theme.white};
`

const Wrapper = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: ${({ minimal }) => (minimal ? 'flex-end' : 'space-between')};

  @media (max-width: ${breakPoints.xs}) {
    height: 40px;
  }
`

const TopBar = ({ minimal }) => {
  const [showNavigation, setShowNavigation] = useState(false)

  return (
    <Background>
      <Container>
        <Wrapper minimal={minimal}>
          {!minimal && (
            <>
              <TopBarText>
                Dijkbeemd 18, 2310 Rijkevorsel • Fienefleur
              </TopBarText>
              <MobileNavigationToggle
                onClick={() => setShowNavigation(!showNavigation)}
              />
            </>
          )}
        </Wrapper>
      </Container>
      {showNavigation && <MobileNavigationMenu />}
    </Background>
  )
}

export { TopBar }
