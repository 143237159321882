import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Button = styled.button`
  appearance: none;
  background-image: ${({ theme, disabled }) =>
    disabled
      ? `linear-gradient(-48deg, ${theme.grey} 0%, ${theme.grey} 100%)`
      : `linear-gradient(-48deg, ${theme.darkPink} 0%, ${theme.orange} 100%)`};
  box-shadow: 0 7px 10px -5px rgba(108, 61, 59, 0.3),
    inset 0 -1px 0 0 rgba(43, 43, 43, 0.07);
  border: none;
  border-radius: ${({ large }) => (large ? '35px' : '17.5px')};
  color: ${({ theme }) => theme.white};
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  font-family: BloggerSans-BoldItalic;
  font-size: ${({ large }) => (large ? '24px' : '14px')};
  height: ${({ large }) => (large ? '70px' : '35px')};
  letter-spacing: 0;
  padding-top: 1px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  text-decoration: none;
  width: ${({ wide, large }) => (large ? '250px' : wide ? '170px' : '150px')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const ButtonWithLink = Button.withComponent(Link)

const ButtonLink = ({ inline, wide, large, ...props }) => (
  <ButtonWithLink
    inline={inline ? 1 : 0}
    wide={wide ? 1 : 0}
    large={large ? 1 : 0}
    {...props}
  />
)

const UnstyledButton = styled.button`
  border: none;
  box-shadow: none;
  background-color: transparent;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
`

export { Button, ButtonLink, UnstyledButton }
