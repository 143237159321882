import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { breakPoints } from '../Layout/theme'

const NavLink = styled(Link)`
  font-family: BloggerSans-Bold;
  font-size: 16px;
  color: ${({ theme }) => theme.brown};
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 40px;
  text-decoration: none;
  flex-shrink: 0;

  @media (max-width: ${breakPoints.sm}) {
    &:first-of-type {
      margin-left: 0;
    }
  }

  &.navlink-active {
    color: ${({ theme }) => theme.darkPink};
    &.white {
      color: ${({ theme }) => theme.white};
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
    &:after {
      content: '';
    }
  }
`

export { NavLink }
