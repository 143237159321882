import styled from '@emotion/styled'
import { breakPoints } from '../Layout/theme'

const TopBarText = styled.p`
  opacity: 0.55;
  font-family: BloggerSans;
  font-size: 14px;
  color: #282c2f;
  letter-spacing: 0;
  line-height: 18px;
  @media (max-width: ${breakPoints.xs}) {
    display: none;
  }
`

export { TopBarText }
