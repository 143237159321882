import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faPhone,
  faHome,
  faCoins,
  faBars,
} from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import '../../assets/fonts/BloggerSans/BloggerSans.css'
import '../../assets/fonts/BloggerSans/BloggerSans-Bold.css'
import '../../assets/fonts/BloggerSans/BloggerSans-BoldItalic.css'
import '../../assets/fonts/BloggerSansLight/BloggerSans-Light.css'
import '../../assets/fonts/BloggerSansMedium/BloggerSans-Medium.css'
import '../../assets/fonts/TheWoodlands/stylesheet.css'

library.add(
  faFacebookSquare,
  faEnvelope,
  faPhone,
  faHome,
  faClock,
  faCoins,
  faBars,
)

const theme = {
  black: '#282C2F',
  brown: '#A4484F',
  darkPink: '#F78C95',
  lightPink: '#FCCBCF',
  orange: '#F69C64',
  white: '#fff',
  grey: '#626262',
  salmon: '#FBF4F4',
}

const breakPoints = {
  xs: '767px',
  sm: '991px',
  md: '1199px',
}

export { theme, breakPoints }
