import styled from '@emotion/styled'
import { Link } from 'gatsby'

const FooterLink = styled(Link)`
  font-family: BloggerSans;
  font-size: 14px;
  color: ${({ theme }) => theme.lightPink};
  letter-spacing: 0;
  line-height: 28px;
  text-decoration: none;
`

FooterLink.External = FooterLink.withComponent('a')
FooterLink.Text = FooterLink.withComponent('div')

export { FooterLink }
