import styled from '@emotion/styled'

const FooterTitle = styled.h4`
  font-family: BloggerSans-Bold;
  font-size: 18px;
  color: ${({ theme }) => theme.darkPink};
  letter-spacing: 0;
  line-height: 28px;
  margin: 0 0 10px;
`

export { FooterTitle }
