import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { breakPoints } from '../Layout/theme'

const ImageWrapper = styled.div`
  @media (max-width: ${breakPoints.sm}) {
    max-width: 100px;
  }
  @media (max-width: ${breakPoints.xs}) {
    max-width: 80px;
  }
  background-color: #fff;
  border-radius: 0 0 1rem 1rem;
  padding: 0.5rem 0;
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const logo = data.logo.childImageSharp.fluid

  return (
    <Link to="/">
      <ImageWrapper>
        <Image fluid={logo} critical fadeIn={false} />
      </ImageWrapper>
    </Link>
  )
}

export { Logo }
