import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '../Icon'
import { NavLink } from './NavLink'

const Toggle = styled.button`
  -webkit-appearance: none;
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
`

const Nav = styled.nav`
  border-top: 1px solid ${({ theme }) => theme.darkPink};
  & ${NavLink} {
    margin-left: 0;
    display: block;
    text-align: center;
    padding: 8px 0;

    &.navlink-active,
    &:hover {
      background: ${({ theme }) => theme.darkPink};
      color: ${({ theme }) => theme.white};
    }
  }
`

const MobileNavigationToggle = ({ onClick }) => (
  <Toggle onClick={onClick} className="visible-xs">
    <Icon icon="bars" style={{ marginRight: 0 }} size="2x" />
  </Toggle>
)

const MobileNavigationMenu = () => {
  return (
    <Nav className="visible-xs">
      <NavLink activeClassName="navlink-active" to="/coaching-therapie/">
        Coaching & therapie
      </NavLink>
      <NavLink activeClassName="navlink-active" to="/massages/">
        Massages
      </NavLink>
      <NavLink activeClassName="navlink-active" to="/healing/">
        Healing
      </NavLink>
      <NavLink activeClassName="navlink-active" to="/living/">
        Living
      </NavLink>
      <NavLink activeClassName="navlink-active" to="/maak-een-afspraak/">
        Maak een afspraak
      </NavLink>
    </Nav>
  )
}

export { MobileNavigationToggle, MobileNavigationMenu }
