import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Container, Column, Row } from '../Grid'
import { ButtonLink } from '../Button'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import { breakPoints } from '../Layout/theme'

const NavWrapper = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
  margin-top: 30px;

  @media (max-width: ${breakPoints.xs}) {
    display: none;
  }
`

const Navigation = () => (
  <nav>
    <Container>
      <Row>
        <Column display={{ md: 2, sm: 2 }}>
          <Logo />
        </Column>
        <Column display={{ md: 9, sm: 10 }} offset={{ md: 1, sm: 0 }}>
          <NavWrapper>
            <NavLink activeClassName="navlink-active" to="/coaching-therapie/">
              Coaching & therapie
            </NavLink>
            <NavLink activeClassName="navlink-active" to="/massages/">
              Massages
            </NavLink>
            <NavLink activeClassName="navlink-active white" to="/healing/">
              Healing
            </NavLink>
            <NavLink activeClassName="navlink-active" to="/living/">
              Living
            </NavLink>
            <ButtonLink
              className="pull-right"
              wide
              inline
              to="/maak-een-afspraak/"
              css={css`
                margin-left: 45px;
              `}>
              Maak een afspraak
            </ButtonLink>
          </NavWrapper>
        </Column>
      </Row>
    </Container>
  </nav>
)

export { Navigation }
