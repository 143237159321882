import styled from '@emotion/styled'
import { breakPoints } from '../Layout/theme'

const H1 = styled.h1`
  font-family: BloggerSans-Medium;
  font-size: 50px;
  color: ${({ theme, color }) => color || theme.white};
  letter-spacing: 0;
  line-height: 51px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  @media (max-width: ${breakPoints.sm}) {
    text-shadow: 1px 1px 2px rgba(0.5, 0.5, 0.5, 0.5);
  }
  @media (max-width: ${breakPoints.xs}) {
    font-size: 24px;
    line-height: 36px;
  }
`

const H2 = styled.h2`
  font-family: BloggerSans-Medium;
  font-size: 36px;
  color: ${({ theme, color }) => color || theme.darkPink};
  letter-spacing: 0;
  line-height: 51px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
`

const H3 = styled.h3`
  font-family: BloggerSans-Medium;
  font-size: 23px;
  color: ${({ theme }) => theme.darkPink};
  letter-spacing: 0;
  line-height: 46px;
`

const H5 = styled.h5`
  font-family: BloggerSans-Medium;
  font-size: 16px;
`

const SubTitle = styled.h2`
  font-family: BloggerSans-Light;
  font-size: 28px;
  color: ${({ theme, color }) => color || theme.brown};
  letter-spacing: 0;
  line-height: 39px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  @media (max-width: ${breakPoints.sm}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export { H1, H2, H3, H5, SubTitle }
