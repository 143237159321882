import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Icon } from '../Icon'
import { FooterTitle } from './FooterTitle'
import { Column, Container, Row } from '../Grid'
import { ButtonLink } from '../Button'
import { FooterLink } from './FooterLink'

const BottomBar = styled.div`
  background-color: ${({ theme }) => theme.white};
  text-align: center;
  font-family: Lato-Bold;
  font-size: 12px;
  letter-spacing: 0;
  margin-top: 30px;

  ${FooterLink.External} {
    color: ${({ theme }) => theme.grey};
    opacity: 0.4;
  }
`

const BorderTop = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(-48deg, ${theme.darkPink} 0%, ${theme.orange} 100%)`};
  box-shadow: 0 7px 10px -5px rgba(108, 61, 59, 0.3),
    inset 0 -1px 0 0 rgba(43, 43, 43, 0.07);
  content: '';
  display: block;
  height: 6px;
`

const Wrapper = styled(BackgroundImage)`
  background-color: ${({ theme }) => theme.black};
`

const Footer = ({ minimal }) => {
  const data = useStaticQuery(
    graphql`
      {
        background: file(relativePath: { eq: "footer-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 150) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `,
  )
  const background = data.background.childImageSharp.fluid
  const logo = data.logo.childImageSharp.fixed

  return (
    <Wrapper Tag="footer" fluid={background}>
      <BorderTop />
      <Container>
        <Row>
          <Column display={{ md: 8, sm: 4 }}>
            <Image
              fixed={logo}
              css={css`
                margin: 1rem 0 21px;
              `}
            />
            {!minimal && (
              <ButtonLink to="/maak-een-afspraak/">
                Maak een afspraak
              </ButtonLink>
            )}
          </Column>
          {!minimal && (
            <Column display={{ md: 2, sm: 4 }} style={{ paddingTop: 20 }}>
              <FooterTitle>Aanbod</FooterTitle>
              <FooterLink style={{ display: 'block' }} to="/coaching-therapie/">
                Coaching &amp; Therapie
              </FooterLink>
              <FooterLink to="/massages/" style={{ display: 'block' }}>
                Massages
              </FooterLink>
              <FooterLink to="/healing/" style={{ display: 'block' }}>
                Healing
              </FooterLink>
              <FooterLink to="/living/" style={{ display: 'block' }}>
                Living
              </FooterLink>
            </Column>
          )}

          <Column
            display={{ md: 2, sm: 4 }}
            offset={{ md: minimal ? 2 : 0 }}
            style={{ paddingTop: 20 }}>
            <FooterTitle>Contact</FooterTitle>
            <ul>
              <li>
                <FooterLink.External
                  href="https://www.google.be/maps/dir//Dijkbeemd,+2310+Rijkevorsel/@51.3570693,4.7670124,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c6a9151cff9e73:0x2cdc94507481cbe9!2m2!1d4.7692064!2d51.357066!3e0"
                  target="_blank">
                  <div
                    css={css`
                      display: flex;
                      align-items: baseline;
                    `}>
                    <Icon icon={['fas', 'home']} flip="horizontal" fixedWidth />
                    Dijkbeemd 18 <br /> 2310 Rijkevorsel
                  </div>
                </FooterLink.External>
              </li>
              <li>
                <FooterLink.External href="tel:00320495776121" target="_blank">
                  <Icon icon={['fas', 'phone']} flip="horizontal" fixedWidth />
                  0495 77 61 21
                </FooterLink.External>
              </li>
              <li>
                <FooterLink.External
                  href="mailto:tinnevw@gmail.com"
                  target="_blank">
                  <Icon icon={['fas', 'envelope']} fixedWidth />
                  tinnevw@gmail.com
                </FooterLink.External>
              </li>
              <li>
                <FooterLink.External
                  href="https://www.facebook.com/fienefleu/"
                  target="_blank">
                  <Icon icon={['fab', 'facebook-square']} fixedWidth />
                  Fienefleur
                </FooterLink.External>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
      <BottomBar>
        <FooterLink.External href="https://cupofcode.be" target="_blank">
          cup of code
        </FooterLink.External>
      </BottomBar>
    </Wrapper>
  )
}

export { Footer }
