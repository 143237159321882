import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { theme } from './theme'
import { TopBar } from '../TopBar'
import { Footer } from '../Footer'

import 'reset-css'
import '../../assets/css/grid.css'

const Layout = ({ children, minimal }) => {
  return (
    <ThemeProvider theme={theme}>
      <TopBar minimal={minimal} />
      <main>{children}</main>
      <Footer minimal={minimal} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
