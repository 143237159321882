import React from 'react'
import { compose, join, map, toPairs } from 'ramda'

const spacer = join(' ')

const mapOffsetToClass = compose(
  spacer,
  map(([mq, cols]) => `col-${mq}-offset-${cols}`),
  toPairs,
)

const mapMqsToClasses = compose(
  spacer,
  map(([mq, cols]) => `col-${mq}-${cols}`),
  toPairs,
)

const Column = ({ children, display = {}, offset = {}, ...props }) => (
  <div
    className={spacer([mapMqsToClasses(display), mapOffsetToClass(offset)])}
    {...props}>
    {children}
  </div>
)

export { Column }
